<template>
    <kurcc-data-table-page-template :dataTableTitle="$vuetify.lang.t('$vuetify.pages.site.siteList')"
                                    :defaultItem="defaultItem"
                                    :headers="headers"
                                    :showDeleteAction="false"
                                    :showToolbarButton="false"
                                    :showUpdateAction="false"
                                    :toolbarButtonDialogTitle="$vuetify.lang.t('$vuetify.pages.site.newSite')"
                                    :toolbarButtonText="$vuetify.lang.t('$vuetify.pages.site.newSite')"
                                    :updateDialogTitle="$vuetify.lang.t('$vuetify.pages.site.editSite')"
                                    actionsSuffix="Site"
                                    show-select>
        <template v-slot:dialog="{item}">
            <kurcc-staff-dialog :item="item"></kurcc-staff-dialog>
        </template>
    </kurcc-data-table-page-template>
</template>

<script>
import Site from "@/modules/sites/models/site";

export default {
    name: "KurccSitesPage",
    components: {
        KurccDataTablePageTemplate: () => import('@/modules/app/templates/KurccDataTablePageTemplate'),
        KurccStaffDialog: () => import('@/modules/staff/components/KurccStaffDialog'),
    },
    data() {
        return {
            headers: [
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.site.siteDataTableHeaders.siteName'),
                    align: 'start',
                    value: 'name',
                },
                {text: this.$vuetify.lang.t('$vuetify.pages.site.siteDataTableHeaders.email'), value: 'email'},
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.site.siteDataTableHeaders.category'),
                    value: 'category.name',
                    sortable: false
                },
                {text: this.$vuetify.lang.t('$vuetify.general.verified'), value: 'is_verified_human_readable'},
                {text: this.$vuetify.lang.t('$vuetify.general.createdAt'), value: 'created_at'},
                {text: this.$vuetify.lang.t('$vuetify.general.actions'), value: 'actions', sortable: false},
            ],
            defaultItem: new Site(),
        }
    }
}
</script>
